import { useState } from 'react'
import './App.css'
import MainOutlet from './layout/main-layout'
import { Route, Routes } from 'react-router-dom'

function App() {
  // const [count, setCount] = useState(0)

  return (
    <Routes>
      <Route path='*' element={<MainOutlet />} ></Route>
    </Routes>
  )
}

export default App;
