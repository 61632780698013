import React from 'react';
import Header from '../components/Header';
import AllRoutes from '../routes/routes';

const MainOutlet = () => {

    return (
        <>
            <div className='main'>
                {/* <Header /> */}
                <div className='main_layout'>
                    <AllRoutes />
                </div>
            </div>
        </>

    )
}

export default MainOutlet;