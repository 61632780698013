import React, { lazy, Suspense } from 'react';
import { Routes, Route, Navigate } from "react-router-dom";

const Home = lazy(() => import("../pages/Home"));
const Signup = lazy(() => import("../pages/Signup"));
const Login = lazy(() => import("../pages/Login"));

const AllRoutes = () => {
    return (
        <Suspense fallback={<div>loading...</div>}>
            <Routes>
                {/* <Route path="/" element={<Home />} ></Route> */}
                {/* <Route path="/" element={<Login />} ></Route> */}
                <Route path="/" element={<Signup />} ></Route>
            </Routes>
        </Suspense>
    )
}

export default AllRoutes